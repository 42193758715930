/* @flow */

export const COUNTRY = {
    AD: 'AD',
    AE: 'AE',
    AG: 'AG',
    AI: 'AI',
    AL: 'AL',
    AM: 'AM',
    AN: 'AN',
    AO: 'AO',
    AR: 'AR',
    AT: 'AT',
    AU: 'AU',
    AW: 'AW',
    AZ: 'AZ',
    BA: 'BA',
    BB: 'BB',
    BE: 'BE',
    BF: 'BF',
    BG: 'BG',
    BH: 'BH',
    BI: 'BI',
    BJ: 'BJ',
    BM: 'BM',
    BN: 'BN',
    BO: 'BO',
    BR: 'BR',
    BS: 'BS',
    BT: 'BT',
    BW: 'BW',
    BY: 'BY',
    BZ: 'BZ',
    C2: 'C2',
    CA: 'CA',
    CD: 'CD',
    CG: 'CG',
    CH: 'CH',
    CI: 'CI',
    CK: 'CK',
    CL: 'CL',
    CM: 'CM',
    CN: 'CN',
    CO: 'CO',
    CR: 'CR',
    CV: 'CV',
    CY: 'CY',
    CZ: 'CZ',
    DE: 'DE',
    DJ: 'DJ',
    DK: 'DK',
    DM: 'DM',
    DO: 'DO',
    DZ: 'DZ',
    EC: 'EC',
    EE: 'EE',
    EG: 'EG',
    ER: 'ER',
    ES: 'ES',
    ET: 'ET',
    FI: 'FI',
    FJ: 'FJ',
    FK: 'FK',
    FM: 'FM',
    FO: 'FO',
    FR: 'FR',
    GA: 'GA',
    GB: 'GB',
    GD: 'GD',
    GE: 'GE',
    GF: 'GF',
    GI: 'GI',
    GL: 'GL',
    GM: 'GM',
    GN: 'GN',
    GP: 'GP',
    GR: 'GR',
    GT: 'GT',
    GW: 'GW',
    GY: 'GY',
    HK: 'HK',
    HN: 'HN',
    HR: 'HR',
    HU: 'HU',
    ID: 'ID',
    IE: 'IE',
    IL: 'IL',
    IN: 'IN',
    IS: 'IS',
    IT: 'IT',
    JM: 'JM',
    JO: 'JO',
    JP: 'JP',
    KE: 'KE',
    KG: 'KG',
    KH: 'KH',
    KI: 'KI',
    KM: 'KM',
    KN: 'KN',
    KR: 'KR',
    KW: 'KW',
    KY: 'KY',
    KZ: 'KZ',
    LA: 'LA',
    LC: 'LC',
    LI: 'LI',
    LK: 'LK',
    LS: 'LS',
    LT: 'LT',
    LU: 'LU',
    LV: 'LV',
    MA: 'MA',
    MC: 'MC',
    MD: 'MD',
    ME: 'ME',
    MG: 'MG',
    MH: 'MH',
    MK: 'MK',
    ML: 'ML',
    MN: 'MN',
    MQ: 'MQ',
    MR: 'MR',
    MS: 'MS',
    MT: 'MT',
    MU: 'MU',
    MV: 'MV',
    MW: 'MW',
    MX: 'MX',
    MY: 'MY',
    MZ: 'MZ',
    NA: 'NA',
    NC: 'NC',
    NE: 'NE',
    NF: 'NF',
    NG: 'NG',
    NI: 'NI',
    NL: 'NL',
    NO: 'NO',
    NP: 'NP',
    NR: 'NR',
    NU: 'NU',
    NZ: 'NZ',
    OM: 'OM',
    PA: 'PA',
    PE: 'PE',
    PF: 'PF',
    PG: 'PG',
    PH: 'PH',
    PL: 'PL',
    PM: 'PM',
    PN: 'PN',
    PT: 'PT',
    PW: 'PW',
    PY: 'PY',
    QA: 'QA',
    RE: 'RE',
    RO: 'RO',
    RS: 'RS',
    RU: 'RU',
    RW: 'RW',
    SA: 'SA',
    SB: 'SB',
    SC: 'SC',
    SE: 'SE',
    SG: 'SG',
    SH: 'SH',
    SI: 'SI',
    SJ: 'SJ',
    SK: 'SK',
    SL: 'SL',
    SM: 'SM',
    SN: 'SN',
    SO: 'SO',
    SR: 'SR',
    ST: 'ST',
    SV: 'SV',
    SZ: 'SZ',
    TC: 'TC',
    TD: 'TD',
    TG: 'TG',
    TH: 'TH',
    TJ: 'TJ',
    TM: 'TM',
    TN: 'TN',
    TO: 'TO',
    TR: 'TR',
    TT: 'TT',
    TV: 'TV',
    TW: 'TW',
    TZ: 'TZ',
    UA: 'UA',
    UG: 'UG',
    US: 'US',
    UY: 'UY',
    VA: 'VA',
    VC: 'VC',
    VE: 'VE',
    VG: 'VG',
    VN: 'VN',
    VU: 'VU',
    WF: 'WF',
    WS: 'WS',
    YE: 'YE',
    YT: 'YT',
    ZA: 'ZA',
    ZM: 'ZM',
    ZW: 'ZW'
};

export const LANG = {
    AR: 'ar',
    CS: 'cs',
    DA: 'da',
    DE: 'de',
    EL: 'el',
    EN: 'en',
    ES: 'es',
    FI: 'fi',
    FR: 'fr',
    HE: 'he',
    HU: 'hu',
    ID: 'id',
    IT: 'it',
    JA: 'ja',
    KO: 'ko',
    NL: 'nl',
    NO: 'no',
    PL: 'pl',
    PT: 'pt',
    RU: 'ru',
    SK: 'sk',
    SV: 'sv',
    TH: 'th',
    TR: 'tr',
    ZH: 'zh'
};

export const LANG_TO_DEFAULT_COUNTRY = {
    [LANG.AR]: COUNTRY.SA,
    [LANG.CS]: COUNTRY.CZ,
    [LANG.DA]: COUNTRY.DK,
    [LANG.DE]: COUNTRY.DE,
    [LANG.EL]: COUNTRY.GR,
    [LANG.EN]: COUNTRY.US,
    [LANG.ES]: COUNTRY.ES,
    [LANG.FI]: COUNTRY.FI,
    [LANG.FR]: COUNTRY.FR,
    [LANG.HE]: COUNTRY.IL,
    [LANG.HU]: COUNTRY.HU,
    [LANG.ID]: COUNTRY.ID,
    [LANG.IT]: COUNTRY.IT,
    [LANG.JA]: COUNTRY.JP,
    [LANG.KO]: COUNTRY.KR,
    [LANG.NL]: COUNTRY.NL,
    [LANG.NO]: COUNTRY.NO,
    [LANG.PL]: COUNTRY.PL,
    [LANG.PT]: COUNTRY.PT,
    [LANG.RU]: COUNTRY.RU,
    [LANG.SK]: COUNTRY.SK,
    [LANG.SV]: COUNTRY.SE,
    [LANG.TH]: COUNTRY.TH,
    [LANG.TR]: COUNTRY.TR,
    [LANG.ZH]: COUNTRY.CN
};

export const ALLOWED_INSTALLMENT_COUNTRIES = [ COUNTRY.BR, COUNTRY.MX ];
export const ALLOWED_INSTALLMENT_PERIOD = {
    BR: [ 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12 ],
    MX: [ 3, 6, 9, 12 ]
};
