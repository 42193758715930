/* @flow */
/* eslint max-lines: off */

export const componentContent = {
    'AD': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'AE': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        },
        'ar': {
            'checkout':  'السداد بواسطة {logo:pp} {logo:paypal}',
            'safer_tag': 'الطريقة الأسهل والأكثر أماناً في الدفع',
            'later_tag': 'اشترِ الآن، وسدّد على دفعات',
            'pay':       'دفع بواسطة {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} شراء الآن',
            'poweredBy': 'مدعوم من {logo:paypal}'
        }
    },
    'AG': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'AI': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'AL': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'AM': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'AN': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'AO': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'AR': {
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'AT': {
        'de': {
            'checkout':  'Direkt zu {logo:pp} {logo:paypal}',
            'safer_tag': 'Einfach schneller und sicherer bezahlen',
            'later_tag': 'Kaufen Sie jetzt und bezahlen Sie nach und nach.',
            'pay':       'Mit {logo:paypal} zahlen',
            'buynow':    '{logo:pp} {logo:paypal} Jetzt kaufen',
            'poweredBy': 'Abgewickelt durch {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'AU': {
        'en': {
            'checkout':  '{logo:pp} {logo:paypal} Checkout',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'AW': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'AZ': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'BA': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'BB': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'BE': {
        'en': {
            'checkout':  'Pay with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, faster way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'nl': {
            'checkout':  'Betalen met {logo:pp} {logo:paypal}',
            'safer_tag': 'De veiligere en snellere manier om te betalen.',
            'later_tag': 'Koop nu. Betaal later.',
            'pay':       'Betalen met {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Nu kopen',
            'poweredBy': 'Mogelijk gemaakt door {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Le réflexe sécurité pour payer',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        }
    },
    'BF': {
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'BG': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'BH': {
        'ar': {
            'checkout':  'السداد بواسطة {logo:pp} {logo:paypal}',
            'safer_tag': 'الطريقة الأسهل والأكثر أماناً في الدفع',
            'later_tag': 'اشترِ الآن، وسدّد على دفعات',
            'pay':       'دفع بواسطة {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} شراء الآن',
            'poweredBy': 'مدعوم من {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'BI': {
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'BJ': {
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'BM': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'BN': {
        'en': {
            'checkout':  '{logo:pp} {logo:paypal} Checkout',
            'safer_tag': 'A safer, faster way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'BO': {
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'BR': {
        'pt': {
            'checkout':           '{logo:pp} {logo:paypal} Finalizar',
            'safer_tag':          'A maneira fácil e segura de pagar.',
            'later_tag':          'Compre agora e pague depois.',
            'pay':                'Pague com {logo:paypal}',
            'buynow':             '{logo:pp} {logo:paypal} Comprar agora',
            'installment':        '{logo:pp} {logo:paypal}  Pagamentos parcelados',
            'installment_period': '{logo:pp} {logo:paypal}  Pague em at\u00e9 [installmentperiod]x sem juros',
            'poweredBy':          'Com tecnologia {logo:paypal}'
        },
        'en': {
            'checkout':           '{logo:pp} {logo:paypal} Checkout',
            'safer_tag':          'The safer, easier way to pay',
            'later_tag':          'Buy Now. Pay Over Time.',
            'pay':                'Pay with {logo:paypal}',
            'buynow':             '{logo:pp} {logo:paypal} Buy Now',
            'installment':        '{logo:pp} {logo:paypal}  Interest free payments',
            'installment_period': '{logo:pp} {logo:paypal}  Pay up to [installmentperiod]x without interest',
            'poweredBy':          'Powered by {logo:paypal}'
        }
    },
    'BS': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'BT': {
        'en': {
            'checkout':  '{logo:pp} {logo:paypal} Checkout',
            'safer_tag': 'A safer, faster way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'BW': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'BY': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'BZ': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'C2': {
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式。',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        },
        'en': {
            'checkout':  '{logo:pp} {logo:paypal} Checkout',
            'safer_tag': 'A safer, faster way to pay.',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'CA': {
        'en': {
            'checkout':  '{logo:pp} {logo:paypal} Checkout',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  '{logo:pp} {logo:paypal} Payer',
            'safer_tag': 'Votre réflexe sécurité pour payer',
            'later_tag': 'Acheter. Payer plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        }
    },
    'CD': {
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'CG': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'CH': {
        'de': {
            'checkout':  'Direkt zu {logo:pp} {logo:paypal}',
            'safer_tag': 'Einfach schneller und sicherer bezahlen',
            'later_tag': 'Kaufen Sie jetzt und bezahlen Sie nach und nach.',
            'pay':       'Mit {logo:paypal} zahlen',
            'buynow':    '{logo:pp} {logo:paypal} Jetzt kaufen',
            'poweredBy': 'Abgewickelt durch {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Le réflexe sécurité pour payer',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'CI': {
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'CK': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'CL': {
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'CM': {
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'CN': {
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'CO': {
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'CR': {
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'CV': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'CY': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'CZ': {
        'cs': {
            'checkout':  'Zaplatit přes {logo:pp} {logo:paypal}',
            'safer_tag': 'Jednodušší a bezpečnější způsob placení',
            'later_tag': 'Nakupujte nyní, plaťte později.',
            'pay':       'Zaplatit přes {logo: paypal}',
            'buynow':    'Koupit ihned přes {logo:pp} {logo:paypal}',
            'poweredBy': 'Využívá službu {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'DE': {
        'de': {
            'checkout':  'Direkt zu {logo:pp} {logo:paypal}',
            'safer_tag': 'Überall schnell und sicher bezahlen.',
            'later_tag': 'Jetzt bei uns bequem in Raten zahlen.',
            'pay':       'Mit {logo:paypal} zahlen',
            'buynow':    '{logo:pp} {logo:paypal} Jetzt kaufen',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'en': {
            'checkout':  '{logo:pp} {logo:paypal} Checkout',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'DJ': {
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'DK': {
        'da': {
            'checkout':  '{logo:pp} {logo:paypal} Betal',
            'safer_tag': 'Betal nemt og sikkert',
            'later_tag': 'Køb nu, betal senere.',
            'pay':       'Betal med {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Køb nu',
            'poweredBy': 'Leveret af {logo:paypal}'
        },
        'en': {
            'checkout':  '{logo:pp} {logo:paypal} Checkout',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'DM': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'DO': {
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'DZ': {
        'ar': {
            'checkout':  'السداد بواسطة {logo:pp} {logo:paypal}',
            'safer_tag': 'الطريقة الأسهل والأكثر أماناً في الدفع',
            'later_tag': 'اشترِ الآن، وسدّد على دفعات',
            'pay':       'دفع بواسطة {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} شراء الآن',
            'poweredBy': 'مدعوم من {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'EC': {
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'EE': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'ru': {
            'checkout':  'Оформить заказ через {logo:pp} {logo:paypal}',
            'safer_tag': 'Более безопасный и простой способ оплаты.',
            'later_tag': 'Покупайте сейчас, платите потом.',
            'pay':       'Оплатить через {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Купить сейчас',
            'poweredBy': 'Обработано {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'EG': {
        'ar': {
            'checkout':  'السداد بواسطة {logo:pp} {logo:paypal}',
            'safer_tag': 'الطريقة الأسهل والأكثر أماناً في الدفع',
            'later_tag': 'اشترِ الآن، وسدّد على دفعات',
            'pay':       'دفع بواسطة {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} شراء الآن',
            'poweredBy': 'مدعوم من {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'ER': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'ES': {
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Tecnología de {logo:paypal}'
        },
        'en': {
            'checkout':  '{logo:pp} {logo:paypal} Checkout',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'ET': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'FI': {
        'fi': {
            'checkout':  '{logo:pp} {logo:paypal}-maksu',
            'safer_tag': 'Turvallisempi ja helpompi maksutapa',
            'later_tag': 'Osta nyt. Maksa vähitellen.',
            'pay':       '{logo:paypal}-maksu',
            'buynow':    '{logo:pp} {logo:paypal} Osta nyt',
            'poweredBy': 'Palvelun tarjoaa {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'FJ': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'FK': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'FM': {
        'en': {
            'checkout':  '{logo:pp} {logo:paypal} Checkout',
            'safer_tag': 'A safer, faster way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'FO': {
        'da': {
            'checkout':  'Betal med {logo:pp} {logo:paypal}',
            'safer_tag': 'Betal nemt og sikkert',
            'later_tag': 'Køb nu, betal senere.',
            'pay':       'Betal med {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Køb nu',
            'poweredBy': 'Leveret af {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'FR': {
        'fr': {
            'checkout':  '{logo:pp} {logo:paypal} Payer',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'en': {
            'checkout':  '{logo:pp} {logo:paypal} Pay',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'GA': {
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'GB': {
        'en': {
            'checkout':  '{logo:pp} {logo:paypal} Checkout',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'GD': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'GE': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'GF': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'GI': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'GL': {
        'da': {
            'checkout':  'Betal med {logo:pp} {logo:paypal}',
            'safer_tag': 'Betal nemt og sikkert',
            'later_tag': 'Køb nu, betal senere.',
            'pay':       'Betal med {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Køb nu',
            'poweredBy': 'Leveret af {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'GM': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'GN': {
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'GP': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'GR': {
        'el': {
            'checkout':  'Ολοκλήρωση αγοράς μέσω {logo:pp} {logo:paypal}',
            'safer_tag': 'Ο ασφαλέστερος και ευκολότερος τρόπος πληρωμής',
            'later_tag': 'Αγοράστε τώρα.  Πληρώστε σε δόσεις.',
            'pay':       'Πληρωμή μέσω {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Αγορά τώρα',
            'poweredBy': 'Με την υποστήριξη του {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'GT': {
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'GW': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'GY': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'HK': {
        'en': {
            'checkout':  '{logo:pp} {logo:paypal} Checkout',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal} 結帳',
            'safer_tag': '更安全、更方便的付款方式',
            'later_tag': '先購買，後付款。',
            'pay':       '使用 {logo:paypal} 付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即買',
            'poweredBy': '支援方： {logo:paypal}'
        }
    },
    'HN': {
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'HR': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'HU': {
        'hu': {
            'checkout':  '{logo:pp} {logo:paypal}-fizetés',
            'safer_tag': 'Biztonságosabb, könnyebb fizetési mód.',
            'later_tag': 'Vásároljon most. Fizessen később.',
            'pay':       '{logo:paypal}-fizetés',
            'buynow':    '{logo:pp} {logo:paypal} Vásárlás',
            'poweredBy': 'Üzemeltető: {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'ID': {
        'id': {
            'checkout':  '{logo:pp} {logo:paypal} Checkout',
            'safer_tag': 'Cara yang lebih mudah dan aman untuk membayar.',
            'later_tag': 'Beli Sekarang. Bayar dalam Jangka Waktu Tertentu.',
            'pay':       'Bayar dengan {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Beli Sekarang',
            'poweredBy': 'Ditunjang teknologi {logo:paypal}'
        },
        'en': {
            'checkout':  '{logo:pp} {logo:paypal} Checkout',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'IE': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'IL': {
        'he': {
            'checkout':  '{logo:pp} {logo:paypal} שלם',
            'safer_tag': '.הדרך הקלה והבטוחה יותר לשלם',
            'later_tag': 'קנה עכשיו. שלם לאורך זמן.',
            'pay':       'שלם באמצעות {logo:paypal}‏',
            'buynow':    '{logo:pp} {logo:paypal} קנה עכשיו',
            'poweredBy': '{logo:paypal} מופעל על-ידי'
        },
        'en': {
            'checkout':  '{logo:pp} {logo:paypal} Checkout',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'IN': {
        'en': {
            'checkout':  '{logo:pp} {logo:paypal} Checkout',
            'safer_tag': 'A safer, faster way to pay.',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'IS': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'IT': {
        'it': {
            'checkout':  '{logo:pp} {logo:paypal} Paga adesso',
            'safer_tag': 'Il modo rapido e sicuro per pagare',
            'later_tag': 'Acquista ora. Paga più tardi.',
            'pay':       'Paga con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Paga adesso',
            'poweredBy': 'Con tecnologia {logo:paypal}'
        },
        'en': {
            'checkout':  '{logo:pp} {logo:paypal} Checkout',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'JM': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'JO': {
        'ar': {
            'checkout':  'السداد بواسطة {logo:pp} {logo:paypal}',
            'safer_tag': 'الطريقة الأسهل والأكثر أماناً في الدفع',
            'later_tag': 'اشترِ الآن، وسدّد على دفعات',
            'pay':       'دفع بواسطة {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} شراء الآن',
            'poweredBy': 'مدعوم من {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'JP': {
        'ja': {
            'checkout':  '{logo:pp} {logo:paypal}で支払う',
            'safer_tag': 'より安全・簡単にお支払い',
            'later_tag': '今すぐ購入して、分割してお支払い。',
            'pay':       '{logo:paypal}で支払う',
            'buynow':    '{logo:pp} {logo:paypal} 購入',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'en': {
            'checkout':  '{logo:pp} {logo:paypal} Checkout',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'KE': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'KG': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'KH': {
        'en': {
            'checkout':  '{logo:pp} {logo:paypal} Checkout',
            'safer_tag': 'A safer, faster way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'KI': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'KM': {
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'KN': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'KR': {
        'ko': {
            'checkout':  '{logo:pp} {logo:paypal} 체크 아웃',
            'safer_tag': '더 안전하고 빠른 결제 방법',
            'later_tag': '지금 구매하고 천천히 결제하세요.',
            'pay':       '{logo:paypal}로 지불하기',
            'buynow':    '{logo:pp} {logo:paypal} 바로 구매',
            'poweredBy': '제공: {logo:paypal}'
        },
        'en': {
            'checkout':  '{logo:pp} {logo:paypal} Checkout',
            'safer_tag': 'A safer, faster way to pay.',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'KW': {
        'ar': {
            'checkout':  'السداد بواسطة {logo:pp} {logo:paypal}',
            'safer_tag': 'الطريقة الأسهل والأكثر أماناً في الدفع',
            'later_tag': 'اشترِ الآن، وسدّد على دفعات',
            'pay':       'دفع بواسطة {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} شراء الآن',
            'poweredBy': 'مدعوم من {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'KY': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'KZ': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'LA': {
        'en': {
            'checkout':  '{logo:pp} {logo:paypal} Checkout',
            'safer_tag': 'A safer, faster way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'LC': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'LI': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'LK': {
        'en': {
            'checkout':  '{logo:pp} {logo:paypal} Checkout',
            'safer_tag': 'A safer, faster way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'LS': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'LT': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'ru': {
            'checkout':  'Оформить заказ через {logo:pp} {logo:paypal}',
            'safer_tag': 'Более безопасный и простой способ оплаты.',
            'later_tag': 'Покупайте сейчас, платите потом.',
            'pay':       'Оплатить через {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Купить сейчас',
            'poweredBy': 'Обработано {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'LU': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'de': {
            'checkout':  'Direkt zu {logo:pp} {logo:paypal}',
            'safer_tag': 'Einfach schneller und sicherer bezahlen',
            'later_tag': 'Kaufen Sie jetzt und bezahlen Sie nach und nach.',
            'pay':       'Mit {logo:paypal} zahlen',
            'buynow':    '{logo:pp} {logo:paypal} Jetzt kaufen',
            'poweredBy': 'Abgewickelt durch {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'LV': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'ru': {
            'checkout':  'Оформить заказ через {logo:pp} {logo:paypal}',
            'safer_tag': 'Более безопасный и простой способ оплаты.',
            'later_tag': 'Покупайте сейчас, платите потом.',
            'pay':       'Оплатить через {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Купить сейчас',
            'poweredBy': 'Обработано {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'MA': {
        'ar': {
            'checkout':  'السداد بواسطة {logo:pp} {logo:paypal}',
            'safer_tag': 'الطريقة الأسهل والأكثر أماناً في الدفع',
            'later_tag': 'اشترِ الآن، وسدّد على دفعات',
            'pay':       'دفع بواسطة {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} شراء الآن',
            'poweredBy': 'مدعوم من {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'MC': {
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'MD': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'ME': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'MG': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'MH': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'MK': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'ML': {
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'MN': {
        'en': {
            'checkout':  '{logo:pp} {logo:paypal} Checkout',
            'safer_tag': 'A safer, faster way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'MQ': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'MR': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'MS': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'MT': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'MU': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'MV': {
        'en': {
            'checkout':  '{logo:pp} {logo:paypal} Checkout',
            'safer_tag': 'A safer, faster way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'MW': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'MX': {
        'es': {
            'checkout':           'Pagar con {logo:pp} {logo:paypal}',
            'safer_tag':          'La forma rápida y segura de pagar',
            'later_tag':          'Compre ahora y pague más adelante.',
            'pay':                'Pagar con {logo:paypal}',
            'buynow':             '{logo:pp} {logo:paypal} Comprar ahora',
            'installment':        '{logo:pp} {logo:paypal}  Pagos en mensualidades',
            'installment_period': '{logo:pp} {logo:paypal}  Pague hasta en [installmentperiod]x mensualidades',
            'poweredBy':          'Desarrollado por {logo:paypal}'
        },
        'en': {
            'checkout':           '{logo:pp} {logo:paypal} Checkout',
            'safer_tag':          'The safer, faster way to pay',
            'later_tag':          'Buy Now. Pay Over Time.',
            'pay':                'Pay with {logo:paypal}',
            'buynow':             '{logo:pp} {logo:paypal} Buy Now',
            'installment':        '{logo:pp} {logo:paypal}  Interest free payments',
            'installment_period': '{logo:pp} {logo:paypal}  Pay up to [installmentperiod]x without interest',
            'poweredBy':          'Powered by {logo:paypal}'
        }
    },
    'MY': {
        'en': {
            'checkout':  '{logo:pp} {logo:paypal} Checkout',
            'safer_tag': 'A safer, faster way to pay.',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'MZ': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'NA': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'NC': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'NE': {
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'NF': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'NG': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'NI': {
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'NL': {
        'nl': {
            'checkout':  '{logo:pp} {logo:paypal} Betalen',
            'safer_tag': 'Een veilige en makkelijke manier om te betalen.',
            'later_tag': 'Koop nu. Betaal later.',
            'pay':       'Betalen met {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Nu kopen',
            'poweredBy': 'Mogelijk gemaakt door {logo:paypal}'
        },
        'en': {
            'checkout':  '{logo:pp} {logo:paypal} Checkout',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'NO': {
        'no': {
            'checkout':  '{logo:pp} {logo:paypal} Betal',
            'safer_tag': 'En trygg og enkel betalingsmetode',
            'later_tag': 'Kjøp nå, betal senere.',
            'pay':       'Betal med {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Kjøp nå',
            'poweredBy': 'Leveres av {logo:paypal}'
        },
        'en': {
            'checkout':  '{logo:pp} {logo:paypal} Checkout',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'NP': {
        'en': {
            'checkout':  '{logo:pp} {logo:paypal} Checkout',
            'safer_tag': 'A safer, faster way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'NR': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'NU': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'NZ': {
        'en': {
            'checkout':  '{logo:pp} {logo:paypal} Checkout',
            'safer_tag': 'A safer, faster way to pay.',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  '{logo:pp} {logo:paypal} Payer',
            'safer_tag': 'Un réflexe sécurité.',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar.',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式。',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'OM': {
        'ar': {
            'checkout':  'السداد بواسطة {logo:pp} {logo:paypal}',
            'safer_tag': 'الطريقة الأسهل والأكثر أماناً في الدفع',
            'later_tag': 'اشترِ الآن، وسدّد على دفعات',
            'pay':       'دفع بواسطة {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} شراء الآن',
            'poweredBy': 'مدعوم من {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'PA': {
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'PE': {
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'PF': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'PG': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'PH': {
        'en': {
            'checkout':  '{logo:pp} {logo:paypal} Checkout',
            'safer_tag': 'A safer, faster way to pay.',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'PL': {
        'pl': {
            'checkout':  '{logo:pp} {logo:paypal} Do kasy',
            'safer_tag': 'Płać wygodnie i bezpiecznie',
            'later_tag': 'Kup teraz. Płać w ratach',
            'pay':       'Zapłać z {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Kup teraz',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'en': {
            'checkout':  '{logo:pp} {logo:paypal} Checkout',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'PM': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'PN': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'PT': {
        'pt': {
            'checkout':  '{logo:pp} {logo:paypal} Checkout',
            'safer_tag': 'A forma rápida e segura de pagar',
            'later_tag': 'Compre agora. Vá pagando.',
            'pay':       'Pagar com {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar agora',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'en': {
            'checkout':  '{logo:pp} {logo:paypal} Checkout',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'PW': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'PY': {
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'QA': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        },
        'ar': {
            'checkout':  'السداد بواسطة {logo:pp} {logo:paypal}',
            'safer_tag': 'الطريقة الأسهل والأكثر أماناً في الدفع',
            'later_tag': 'اشترِ الآن، وسدّد على دفعات',
            'pay':       'دفع بواسطة {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} شراء الآن',
            'poweredBy': 'مدعوم من {logo:paypal}'
        }
    },
    'RE': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'RO': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'RS': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'RU': {
        'ru': {
            'checkout':  '{logo:pp} {logo:paypal} Оформить покупку',
            'safer_tag': 'Более безопасный и простой способ оплаты.',
            'later_tag': 'Покупайте сейчас, платите потом.',
            'pay':       'Оплатить через {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Купить сейчас',
            'poweredBy': 'Обработано {logo:paypal}'
        },
        'en': {
            'checkout':  '{logo:pp} {logo:paypal} Checkout',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'RW': {
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'SA': {
        'ar': {
            'checkout':  'السداد بواسطة {logo:pp} {logo:paypal}',
            'safer_tag': 'الطريقة الأسهل والأكثر أماناً في الدفع',
            'later_tag': 'اشترِ الآن، وسدّد على دفعات',
            'pay':       'دفع بواسطة {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} شراء الآن',
            'poweredBy': 'مدعوم من {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'SB': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'SC': {
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'SE': {
        'sv': {
            'checkout':  '{logo:pp} {logo:paypal} Betala',
            'safer_tag': 'Ett tryggt och smidigt sätt att betala',
            'later_tag': 'Köp nu, betala senare',
            'pay':       'Betala med {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Köp nu',
            'poweredBy': 'Tillhandahålls av {logo:paypal}'
        },
        'en': {
            'checkout':  '{logo:pp} {logo:paypal} Checkout',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'SG': {
        'en': {
            'checkout':  '{logo:pp} {logo:paypal} Checkout',
            'safer_tag': 'A safer, faster way to pay.',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'SH': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'SI': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'SJ': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'SK': {
        'sk': {
            'checkout':  'Zaplatiť cez {logo:pp} {logo:paypal}',
            'safer_tag': 'Jednoduchší a bezpečnejší spôsob platby',
            'later_tag': 'Nakúpte teraz, zaplaťte postupne',
            'pay':       'Zaplatiť cez {logo: paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Kúpiť',
            'poweredBy': 'Používa technológiu {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'SL': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'SM': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'SN': {
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'SO': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'SR': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'ST': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'SV': {
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'SZ': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'TC': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'TD': {
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'TG': {
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'TH': {
        'th': {
            'checkout':  '{logo:pp} {logo:paypal} ชำระเงิน',
            'safer_tag': 'วิธีชำระเงินที่ปลอดภัยและง่ายกว่า',
            'later_tag': 'ซื้อวันนี้ แล้วค่อยๆ จ่ายทีหลัง',
            'pay':       'ชำระเงินด้วย {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} ซื้อทันที',
            'poweredBy': 'ให้บริการโดย {logo:paypal}'
        },
        'en': {
            'checkout':  '{logo:pp} {logo:paypal} Checkout',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'TJ': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'TM': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'TN': {
        'ar': {
            'checkout':  'السداد بواسطة {logo:pp} {logo:paypal}',
            'safer_tag': 'الطريقة الأسهل والأكثر أماناً في الدفع',
            'later_tag': 'اشترِ الآن، وسدّد على دفعات',
            'pay':       'دفع بواسطة {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} شراء الآن',
            'poweredBy': 'مدعوم من {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'TO': {
        'en': {
            'checkout':  '{logo:pp} {logo:paypal} Checkout',
            'safer_tag': 'A safer, faster way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'TR': {
        'tr': {
            'checkout':  '{logo:pp} {logo:paypal} ile Satın Alın',
            'safer_tag': 'Ödeme yapmanın daha güvenli ve kolay yolu',
            'later_tag': 'Şimdi Alın. Daha Sonra Ödeyin.',
            'pay':       '{logo:paypal} ile Öde',
            'buynow':    '{logo:pp} {logo:paypal} Hemen Satın Alın',
            'poweredBy': 'Çalıştıran {logo:paypal}'
        },
        'en': {
            'checkout':  '{logo:pp} {logo:paypal} Checkout',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'TT': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'TV': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'TW': {
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal} 結帳',
            'safer_tag': '更安全、更方便的付款方式',
            'later_tag': '先購買，後付款。',
            'pay':       '使用 {logo:paypal} 付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即購',
            'poweredBy': '服務提供者： {logo:paypal}'
        },
        'en': {
            'checkout':  '{logo:pp} {logo:paypal} Checkout',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'TZ': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'UA': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'ru': {
            'checkout':  'Оформить заказ через {logo:pp} {logo:paypal}',
            'safer_tag': 'Более безопасный и простой способ оплаты.',
            'later_tag': 'Покупайте сейчас, платите потом.',
            'pay':       'Оплатить через {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Купить сейчас',
            'poweredBy': 'Обработано {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'UG': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'US': {
        'en': {
            'checkout':  '{logo:pp} {logo:paypal} Checkout',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'dual_tag':  'Two easy ways to pay',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  '{logo:pp} {logo:paypal} Payer',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  'Pagar con {logo:pp} {logo:paypal}',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '使用{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'UY': {
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'VA': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'VC': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'VE': {
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'VG': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'VN': {
        'en': {
            'checkout':  '{logo:pp} {logo:paypal} Checkout',
            'safer_tag': 'A safer, faster way to pay.',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'VU': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'WF': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'WS': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    },
    'YE': {
        'ar': {
            'checkout':  'السداد بواسطة {logo:pp} {logo:paypal}',
            'safer_tag': 'الطريقة الأسهل والأكثر أماناً في الدفع',
            'later_tag': 'اشترِ الآن، وسدّد على دفعات',
            'pay':       'دفع بواسطة {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} شراء الآن',
            'poweredBy': 'مدعوم من {logo:paypal}'
        },
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'YT': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'ZA': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'ZM': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        },
        'fr': {
            'checkout':  'Payer avec {logo:pp} {logo:paypal}',
            'safer_tag': 'Votre réflexe sécurité pour payer en ligne',
            'later_tag': 'Achetez maintenant et payez plus tard.',
            'pay':       'Payer avec {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Acheter',
            'poweredBy': 'Optimisé par {logo:paypal}'
        },
        'es': {
            'checkout':  '{logo:pp} {logo:paypal} Pagar',
            'safer_tag': 'La forma rápida y segura de pagar',
            'later_tag': 'Compre ahora y pague más adelante.',
            'pay':       'Pagar con {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Comprar ahora',
            'poweredBy': 'Desarrollado por {logo:paypal}'
        },
        'zh': {
            'checkout':  '{logo:pp} {logo:paypal}结账',
            'safer_tag': '更安全、更便捷的付款方式',
            'later_tag': '立即购买，分期付款。',
            'pay':       '用{logo:paypal}付款',
            'buynow':    '{logo:pp} {logo:paypal} 立即购买',
            'poweredBy': '技术支持提供方： {logo:paypal}'
        }
    },
    'ZW': {
        'en': {
            'checkout':  'Check out with {logo:pp} {logo:paypal}',
            'safer_tag': 'The safer, easier way to pay',
            'later_tag': 'Buy Now. Pay Over Time.',
            'pay':       'Pay with {logo:paypal}',
            'buynow':    '{logo:pp} {logo:paypal} Buy Now',
            'poweredBy': 'Powered by {logo:paypal}'
        }
    }
};
