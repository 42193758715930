/* @flow */
/* eslint max-lines: off */

export const containerContent = {
    'AT': {
        'de': {
            'windowMessage': 'Sie sehen das sichere Browserfenster von PayPal nicht? Hier k\u00F6nnen Sie es wieder \u00F6ffnen und Ihren Einkauf abschlie\u00DFen.',
            'continue':      'Weiter'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'ZW': {
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'ZM': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'ZA': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'YT': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'YE': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        },
        'ar': {
            'windowMessage': '\u0644\u0627 \u062A\u0631\u0649 \u0645\u062A\u0635\u0641\u062D PayPal \u0627\u0644\u0622\u0645\u0646\u061F \u0633\u0646\u0633\u0627\u0639\u062F\u0643 \u0641\u064A \u0625\u0639\u0627\u062F\u0629 \u0641\u062A\u062D \u0627\u0644\u0646\u0627\u0641\u0630\u0629 \u0644\u0627\u0633\u062A\u0643\u0645\u0627\u0644 \u0645\u0634\u062A\u0631\u064A\u0627\u062A\u0643. \u00A0 ',
            'continue':      '\u0645\u062A\u0627\u0628\u0639\u0629'
        }
    },
    'WS': {
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.  ',
            'continue':      'Continue'
        }
    },
    'WF': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'VU': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda completar su compra. ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat. ',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.  ',
            'continue':      'Continue'
        }
    },
    'VG': {
        'zh': {
            'windowMessage': '\u6CA1\u770B\u5230PayPal\u4ED8\u6B3E\u9875\u9762\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo puede ver la p\u00E1gina de pago de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Vous ne voyez pas la page de Paiement PayPal ? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the PayPal payment page? We\u2019ll help you re-launch the window to complete your purchase.',
            'continue':      'Continue'
        }
    },
    'VE': {
        'zh': {
            'windowMessage': '\u6CA1\u770B\u5230PayPal\u4ED8\u6B3E\u9875\u9762\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'fr': {
            'windowMessage': 'Vous ne voyez pas la page de Paiement PayPal ? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the PayPal payment page? We\u2019ll help you re-launch the window to complete your purchase.',
            'continue':      'Continue'
        },
        'es': {
            'windowMessage': '\u00BFNo puede ver la p\u00E1gina de pago de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.',
            'continue':      'Continuar'
        }
    },
    'VC': {
        'zh': {
            'windowMessage': '\u6CA1\u770B\u5230PayPal\u4ED8\u6B3E\u9875\u9762\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo puede ver la p\u00E1gina de pago de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Vous ne voyez pas la page de Paiement PayPal ? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the PayPal payment page? We\u2019ll help you re-launch the window to complete your purchase.',
            'continue':      'Continue'
        }
    },
    'VA': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'UY': {
        'zh': {
            'windowMessage': '\u6CA1\u770B\u5230PayPal\u4ED8\u6B3E\u9875\u9762\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'fr': {
            'windowMessage': 'Vous ne voyez pas la page de Paiement PayPal ? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the PayPal payment page? We\u2019ll help you re-launch the window to complete your purchase.',
            'continue':      'Continue'
        },
        'es': {
            'windowMessage': '\u00BFNo puede ver la p\u00E1gina de pago de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.',
            'continue':      'Continuar'
        }
    },
    'UG': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'TZ': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'TV': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda completar su compra. ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat. ',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.  ',
            'continue':      'Continue'
        }
    },
    'TT': {
        'zh': {
            'windowMessage': '\u6CA1\u770B\u5230PayPal\u4ED8\u6B3E\u9875\u9762\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo puede ver la p\u00E1gina de pago de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Vous ne voyez pas la page de Paiement PayPal ? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the PayPal payment page? We\u2019ll help you re-launch the window to complete your purchase.',
            'continue':      'Continue'
        }
    },
    'TO': {
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.  ',
            'continue':      'Continue'
        }
    },
    'TN': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        },
        'ar': {
            'windowMessage': '\u0644\u0627 \u062A\u0631\u0649 \u0645\u062A\u0635\u0641\u062D PayPal \u0627\u0644\u0622\u0645\u0646\u061F \u0633\u0646\u0633\u0627\u0639\u062F\u0643 \u0641\u064A \u0625\u0639\u0627\u062F\u0629 \u0641\u062A\u062D \u0627\u0644\u0646\u0627\u0641\u0630\u0629 \u0644\u0627\u0633\u062A\u0643\u0645\u0627\u0644 \u0645\u0634\u062A\u0631\u064A\u0627\u062A\u0643. \u00A0 ',
            'continue':      '\u0645\u062A\u0627\u0628\u0639\u0629'
        }
    },
    'TM': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'TJ': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'TG': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        }
    },
    'TD': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        }
    },
    'TC': {
        'zh': {
            'windowMessage': '\u6CA1\u770B\u5230PayPal\u4ED8\u6B3E\u9875\u9762\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo puede ver la p\u00E1gina de pago de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Vous ne voyez pas la page de Paiement PayPal ? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the PayPal payment page? We\u2019ll help you re-launch the window to complete your purchase.',
            'continue':      'Continue'
        }
    },
    'SZ': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'SV': {
        'zh': {
            'windowMessage': '\u6CA1\u770B\u5230PayPal\u4ED8\u6B3E\u9875\u9762\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'fr': {
            'windowMessage': 'Vous ne voyez pas la page de Paiement PayPal ? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the PayPal payment page? We\u2019ll help you re-launch the window to complete your purchase.',
            'continue':      'Continue'
        },
        'es': {
            'windowMessage': '\u00BFNo puede ver la p\u00E1gina de pago de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.',
            'continue':      'Continuar'
        }
    },
    'ST': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'SR': {
        'zh': {
            'windowMessage': '\u6CA1\u770B\u5230PayPal\u4ED8\u6B3E\u9875\u9762\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo puede ver la p\u00E1gina de pago de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Vous ne voyez pas la page de Paiement PayPal ? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the PayPal payment page? We\u2019ll help you re-launch the window to complete your purchase.',
            'continue':      'Continue'
        }
    },
    'SO': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'SN': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        }
    },
    'SM': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'SL': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'SJ': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'SH': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'SC': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        }
    },
    'SB': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda completar su compra. ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat. ',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.  ',
            'continue':      'Continue'
        }
    },
    'SA': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        },
        'ar': {
            'windowMessage': '\u0644\u0627 \u062A\u0631\u0649 \u0645\u062A\u0635\u0641\u062D PayPal \u0627\u0644\u0622\u0645\u0646\u061F \u0633\u0646\u0633\u0627\u0639\u062F\u0643 \u0641\u064A \u0625\u0639\u0627\u062F\u0629 \u0641\u062A\u062D \u0627\u0644\u0646\u0627\u0641\u0630\u0629 \u0644\u0627\u0633\u062A\u0643\u0645\u0627\u0644 \u0645\u0634\u062A\u0631\u064A\u0627\u062A\u0643. \u00A0 ',
            'continue':      '\u0645\u062A\u0627\u0628\u0639\u0629'
        }
    },
    'RW': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        }
    },
    'RS': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'RE': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'QA': {
        'ar': {
            'windowMessage': '\u0644\u0627 \u062A\u0631\u0649 \u0645\u062A\u0635\u0641\u062D PayPal \u0627\u0644\u0622\u0645\u0646\u061F \u0633\u0646\u0633\u0627\u0639\u062F\u0643 \u0641\u064A \u0625\u0639\u0627\u062F\u0629 \u0641\u062A\u062D \u0627\u0644\u0646\u0627\u0641\u0630\u0629 \u0644\u0627\u0633\u062A\u0643\u0645\u0627\u0644 \u0645\u0634\u062A\u0631\u064A\u0627\u062A\u0643. \u00A0 ',
            'continue':      '\u0645\u062A\u0627\u0628\u0639\u0629'
        },
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'PY': {
        'en': {
            'windowMessage': 'Don\u2019t see the PayPal payment page? We\u2019ll help you re-launch the window to complete your purchase.',
            'continue':      'Continue'
        },
        'es': {
            'windowMessage': '\u00BFNo puede ver la p\u00E1gina de pago de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.',
            'continue':      'Continuar'
        }
    },
    'PW': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda completar su compra. ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat. ',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.  ',
            'continue':      'Continue'
        }
    },
    'PN': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda completar su compra. ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat. ',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.  ',
            'continue':      'Continue'
        }
    },
    'PM': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'PG': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda completar su compra. ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat. ',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.  ',
            'continue':      'Continue'
        }
    },
    'PF': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'PE': {
        'zh': {
            'windowMessage': '\u6CA1\u770B\u5230PayPal\u4ED8\u6B3E\u9875\u9762\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'fr': {
            'windowMessage': 'Vous ne voyez pas la page de Paiement PayPal ? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the PayPal payment page? We\u2019ll help you re-launch the window to complete your purchase.',
            'continue':      'Continue'
        },
        'es': {
            'windowMessage': '\u00BFNo puede ver la p\u00E1gina de pago de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.',
            'continue':      'Continuar'
        }
    },
    'PA': {
        'zh': {
            'windowMessage': '\u6CA1\u770B\u5230PayPal\u4ED8\u6B3E\u9875\u9762\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'fr': {
            'windowMessage': 'Vous ne voyez pas la page de Paiement PayPal ? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the PayPal payment page? We\u2019ll help you re-launch the window to complete your purchase.',
            'continue':      'Continue'
        },
        'es': {
            'windowMessage': '\u00BFNo puede ver la p\u00E1gina de pago de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.',
            'continue':      'Continuar'
        }
    },
    'OM': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        },
        'ar': {
            'windowMessage': '\u0644\u0627 \u062A\u0631\u0649 \u0645\u062A\u0635\u0641\u062D PayPal \u0627\u0644\u0622\u0645\u0646\u061F \u0633\u0646\u0633\u0627\u0639\u062F\u0643 \u0641\u064A \u0625\u0639\u0627\u062F\u0629 \u0641\u062A\u062D \u0627\u0644\u0646\u0627\u0641\u0630\u0629 \u0644\u0627\u0633\u062A\u0643\u0645\u0627\u0644 \u0645\u0634\u062A\u0631\u064A\u0627\u062A\u0643. \u00A0 ',
            'continue':      '\u0645\u062A\u0627\u0628\u0639\u0629'
        }
    },
    'NU': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda completar su compra. ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat. ',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.  ',
            'continue':      'Continue'
        }
    },
    'NR': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda completar su compra. ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat. ',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.  ',
            'continue':      'Continue'
        }
    },
    'NP': {
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.  ',
            'continue':      'Continue'
        }
    },
    'NI': {
        'zh': {
            'windowMessage': '\u6CA1\u770B\u5230PayPal\u4ED8\u6B3E\u9875\u9762\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'fr': {
            'windowMessage': 'Vous ne voyez pas la page de Paiement PayPal ? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the PayPal payment page? We\u2019ll help you re-launch the window to complete your purchase.',
            'continue':      'Continue'
        },
        'es': {
            'windowMessage': '\u00BFNo puede ver la p\u00E1gina de pago de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.',
            'continue':      'Continuar'
        }
    },
    'NG': {
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'NF': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda completar su compra. ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat. ',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.  ',
            'continue':      'Continue'
        }
    },
    'NE': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        }
    },
    'NC': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'NA': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'MZ': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'MW': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'MV': {
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.  ',
            'continue':      'Continue'
        }
    },
    'MU': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'MT': {
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'MS': {
        'zh': {
            'windowMessage': '\u6CA1\u770B\u5230PayPal\u4ED8\u6B3E\u9875\u9762\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo puede ver la p\u00E1gina de pago de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Vous ne voyez pas la page de Paiement PayPal ? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the PayPal payment page? We\u2019ll help you re-launch the window to complete your purchase.',
            'continue':      'Continue'
        }
    },
    'MR': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'MQ': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'MN': {
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.  ',
            'continue':      'Continue'
        }
    },
    'ML': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        }
    },
    'MK': {
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'MH': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda completar su compra. ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat. ',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.  ',
            'continue':      'Continue'
        }
    },
    'MG': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'ME': {
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'MD': {
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'MC': {
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        }
    },
    'MA': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        },
        'ar': {
            'windowMessage': '\u0644\u0627 \u062A\u0631\u0649 \u0645\u062A\u0635\u0641\u062D PayPal \u0627\u0644\u0622\u0645\u0646\u061F \u0633\u0646\u0633\u0627\u0639\u062F\u0643 \u0641\u064A \u0625\u0639\u0627\u062F\u0629 \u0641\u062A\u062D \u0627\u0644\u0646\u0627\u0641\u0630\u0629 \u0644\u0627\u0633\u062A\u0643\u0645\u0627\u0644 \u0645\u0634\u062A\u0631\u064A\u0627\u062A\u0643. \u00A0 ',
            'continue':      '\u0645\u062A\u0627\u0628\u0639\u0629'
        }
    },
    'LS': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'LK': {
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.  ',
            'continue':      'Continue'
        }
    },
    'LI': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'LC': {
        'zh': {
            'windowMessage': '\u6CA1\u770B\u5230PayPal\u4ED8\u6B3E\u9875\u9762\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo puede ver la p\u00E1gina de pago de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Vous ne voyez pas la page de Paiement PayPal ? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the PayPal payment page? We\u2019ll help you re-launch the window to complete your purchase.',
            'continue':      'Continue'
        }
    },
    'LA': {
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.  ',
            'continue':      'Continue'
        }
    },
    'KZ': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'KY': {
        'zh': {
            'windowMessage': '\u6CA1\u770B\u5230PayPal\u4ED8\u6B3E\u9875\u9762\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo puede ver la p\u00E1gina de pago de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Vous ne voyez pas la page de Paiement PayPal ? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the PayPal payment page? We\u2019ll help you re-launch the window to complete your purchase.',
            'continue':      'Continue'
        }
    },
    'KW': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        },
        'ar': {
            'windowMessage': '\u0644\u0627 \u062A\u0631\u0649 \u0645\u062A\u0635\u0641\u062D PayPal \u0627\u0644\u0622\u0645\u0646\u061F \u0633\u0646\u0633\u0627\u0639\u062F\u0643 \u0641\u064A \u0625\u0639\u0627\u062F\u0629 \u0641\u062A\u062D \u0627\u0644\u0646\u0627\u0641\u0630\u0629 \u0644\u0627\u0633\u062A\u0643\u0645\u0627\u0644 \u0645\u0634\u062A\u0631\u064A\u0627\u062A\u0643. \u00A0 ',
            'continue':      '\u0645\u062A\u0627\u0628\u0639\u0629'
        }
    },
    'KN': {
        'zh': {
            'windowMessage': '\u6CA1\u770B\u5230PayPal\u4ED8\u6B3E\u9875\u9762\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo puede ver la p\u00E1gina de pago de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Vous ne voyez pas la page de Paiement PayPal ? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the PayPal payment page? We\u2019ll help you re-launch the window to complete your purchase.',
            'continue':      'Continue'
        }
    },
    'KM': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        }
    },
    'KI': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda completar su compra. ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat. ',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.  ',
            'continue':      'Continue'
        }
    },
    'KH': {
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.  ',
            'continue':      'Continue'
        }
    },
    'KG': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'KE': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'JO': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        },
        'ar': {
            'windowMessage': '\u0644\u0627 \u062A\u0631\u0649 \u0645\u062A\u0635\u0641\u062D PayPal \u0627\u0644\u0622\u0645\u0646\u061F \u0633\u0646\u0633\u0627\u0639\u062F\u0643 \u0641\u064A \u0625\u0639\u0627\u062F\u0629 \u0641\u062A\u062D \u0627\u0644\u0646\u0627\u0641\u0630\u0629 \u0644\u0627\u0633\u062A\u0643\u0645\u0627\u0644 \u0645\u0634\u062A\u0631\u064A\u0627\u062A\u0643. \u00A0 ',
            'continue':      '\u0645\u062A\u0627\u0628\u0639\u0629'
        }
    },
    'JM': {
        'zh': {
            'windowMessage': '\u6CA1\u770B\u5230PayPal\u4ED8\u6B3E\u9875\u9762\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'fr': {
            'windowMessage': 'Vous ne voyez pas la page de Paiement PayPal ? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the PayPal payment page? We\u2019ll help you re-launch the window to complete your purchase.',
            'continue':      'Continue'
        },
        'es': {
            'windowMessage': '\u00BFNo puede ver la p\u00E1gina de pago de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.',
            'continue':      'Continuar'
        }
    },
    'IS': {
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'HR': {
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'HN': {
        'zh': {
            'windowMessage': '\u6CA1\u770B\u5230PayPal\u4ED8\u6B3E\u9875\u9762\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'fr': {
            'windowMessage': 'Vous ne voyez pas la page de Paiement PayPal ? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the PayPal payment page? We\u2019ll help you re-launch the window to complete your purchase.',
            'continue':      'Continue'
        },
        'es': {
            'windowMessage': '\u00BFNo puede ver la p\u00E1gina de pago de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.',
            'continue':      'Continuar'
        }
    },
    'GY': {
        'zh': {
            'windowMessage': '\u6CA1\u770B\u5230PayPal\u4ED8\u6B3E\u9875\u9762\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo puede ver la p\u00E1gina de pago de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Vous ne voyez pas la page de Paiement PayPal ? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the PayPal payment page? We\u2019ll help you re-launch the window to complete your purchase.',
            'continue':      'Continue'
        }
    },
    'GW': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'GT': {
        'zh': {
            'windowMessage': '\u6CA1\u770B\u5230PayPal\u4ED8\u6B3E\u9875\u9762\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'fr': {
            'windowMessage': 'Vous ne voyez pas la page de Paiement PayPal ? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the PayPal payment page? We\u2019ll help you re-launch the window to complete your purchase.',
            'continue':      'Continue'
        },
        'es': {
            'windowMessage': '\u00BFNo puede ver la p\u00E1gina de pago de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.',
            'continue':      'Continuar'
        }
    },
    'GP': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'GN': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        }
    },
    'GM': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'GL': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        },
        'da': {
            'windowMessage': 'Kan du ikke se PayPals sikre browser? Vi hj\u00E6lper dig med at genstarte vinduet, s\u00E5 du kan betale.',
            'continue':      'Forts\u00E6t'
        }
    },
    'GI': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'GF': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'GE': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'GD': {
        'zh': {
            'windowMessage': '\u6CA1\u770B\u5230PayPal\u4ED8\u6B3E\u9875\u9762\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo puede ver la p\u00E1gina de pago de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Vous ne voyez pas la page de Paiement PayPal ? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the PayPal payment page? We\u2019ll help you re-launch the window to complete your purchase.',
            'continue':      'Continue'
        }
    },
    'GA': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        }
    },
    'FO': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        },
        'da': {
            'windowMessage': 'Kan du ikke se PayPals sikre browser? Vi hj\u00E6lper dig med at genstarte vinduet, s\u00E5 du kan betale.',
            'continue':      'Forts\u00E6t'
        }
    },
    'FM': {
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.  ',
            'continue':      'Continue'
        }
    },
    'FK': {
        'zh': {
            'windowMessage': '\u6CA1\u770B\u5230PayPal\u4ED8\u6B3E\u9875\u9762\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo puede ver la p\u00E1gina de pago de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Vous ne voyez pas la page de Paiement PayPal ? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the PayPal payment page? We\u2019ll help you re-launch the window to complete your purchase.',
            'continue':      'Continue'
        }
    },
    'FJ': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda completar su compra. ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat. ',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.  ',
            'continue':      'Continue'
        }
    },
    'ET': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'ER': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'EG': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        },
        'ar': {
            'windowMessage': '\u0644\u0627 \u062A\u0631\u0649 \u0645\u062A\u0635\u0641\u062D PayPal \u0627\u0644\u0622\u0645\u0646\u061F \u0633\u0646\u0633\u0627\u0639\u062F\u0643 \u0641\u064A \u0625\u0639\u0627\u062F\u0629 \u0641\u062A\u062D \u0627\u0644\u0646\u0627\u0641\u0630\u0629 \u0644\u0627\u0633\u062A\u0643\u0645\u0627\u0644 \u0645\u0634\u062A\u0631\u064A\u0627\u062A\u0643. \u00A0 ',
            'continue':      '\u0645\u062A\u0627\u0628\u0639\u0629'
        }
    },
    'EC': {
        'zh': {
            'windowMessage': '\u6CA1\u770B\u5230PayPal\u4ED8\u6B3E\u9875\u9762\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'fr': {
            'windowMessage': 'Vous ne voyez pas la page de Paiement PayPal ? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the PayPal payment page? We\u2019ll help you re-launch the window to complete your purchase.',
            'continue':      'Continue'
        },
        'es': {
            'windowMessage': '\u00BFNo puede ver la p\u00E1gina de pago de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.',
            'continue':      'Continuar'
        }
    },
    'DZ': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        },
        'ar': {
            'windowMessage': '\u0644\u0627 \u062A\u0631\u0649 \u0645\u062A\u0635\u0641\u062D PayPal \u0627\u0644\u0622\u0645\u0646\u061F \u0633\u0646\u0633\u0627\u0639\u062F\u0643 \u0641\u064A \u0625\u0639\u0627\u062F\u0629 \u0641\u062A\u062D \u0627\u0644\u0646\u0627\u0641\u0630\u0629 \u0644\u0627\u0633\u062A\u0643\u0645\u0627\u0644 \u0645\u0634\u062A\u0631\u064A\u0627\u062A\u0643. \u00A0 ',
            'continue':      '\u0645\u062A\u0627\u0628\u0639\u0629'
        }
    },
    'DO': {
        'zh': {
            'windowMessage': '\u6CA1\u770B\u5230PayPal\u4ED8\u6B3E\u9875\u9762\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'fr': {
            'windowMessage': 'Vous ne voyez pas la page de Paiement PayPal ? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the PayPal payment page? We\u2019ll help you re-launch the window to complete your purchase.',
            'continue':      'Continue'
        },
        'es': {
            'windowMessage': '\u00BFNo puede ver la p\u00E1gina de pago de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.',
            'continue':      'Continuar'
        }
    },
    'DM': {
        'zh': {
            'windowMessage': '\u6CA1\u770B\u5230PayPal\u4ED8\u6B3E\u9875\u9762\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo puede ver la p\u00E1gina de pago de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Vous ne voyez pas la page de Paiement PayPal ? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the PayPal payment page? We\u2019ll help you re-launch the window to complete your purchase.',
            'continue':      'Continue'
        }
    },
    'DJ': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        }
    },
    'CY': {
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'CV': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'CR': {
        'zh': {
            'windowMessage': '\u6CA1\u770B\u5230PayPal\u4ED8\u6B3E\u9875\u9762\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'fr': {
            'windowMessage': 'Vous ne voyez pas la page de Paiement PayPal ? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the PayPal payment page? We\u2019ll help you re-launch the window to complete your purchase.',
            'continue':      'Continue'
        },
        'es': {
            'windowMessage': '\u00BFNo puede ver la p\u00E1gina de pago de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.',
            'continue':      'Continuar'
        }
    },
    'CO': {
        'zh': {
            'windowMessage': '\u6CA1\u770B\u5230PayPal\u4ED8\u6B3E\u9875\u9762\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'fr': {
            'windowMessage': 'Vous ne voyez pas la page de Paiement PayPal ? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the PayPal payment page? We\u2019ll help you re-launch the window to complete your purchase.',
            'continue':      'Continue'
        },
        'es': {
            'windowMessage': '\u00BFNo puede ver la p\u00E1gina de pago de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.',
            'continue':      'Continuar'
        }
    },
    'CM': {
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        }
    },
    'CL': {
        'zh': {
            'windowMessage': '\u6CA1\u770B\u5230PayPal\u4ED8\u6B3E\u9875\u9762\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'fr': {
            'windowMessage': 'Vous ne voyez pas la page de Paiement PayPal ? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the PayPal payment page? We\u2019ll help you re-launch the window to complete your purchase.',
            'continue':      'Continue'
        },
        'es': {
            'windowMessage': '\u00BFNo puede ver la p\u00E1gina de pago de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.',
            'continue':      'Continuar'
        }
    },
    'CK': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'CI': {
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        }
    },
    'CG': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'CD': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        }
    },
    'BZ': {
        'zh': {
            'windowMessage': '\u6CA1\u770B\u5230PayPal\u4ED8\u6B3E\u9875\u9762\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'fr': {
            'windowMessage': 'Vous ne voyez pas la page de Paiement PayPal ? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the PayPal payment page? We\u2019ll help you re-launch the window to complete your purchase.',
            'continue':      'Continue'
        },
        'es': {
            'windowMessage': '\u00BFNo puede ver la p\u00E1gina de pago de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.',
            'continue':      'Continuar'
        }
    },
    'BY': {
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'BW': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'BT': {
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.  ',
            'continue':      'Continue'
        }
    },
    'BS': {
        'zh': {
            'windowMessage': '\u6CA1\u770B\u5230PayPal\u4ED8\u6B3E\u9875\u9762\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo puede ver la p\u00E1gina de pago de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Vous ne voyez pas la page de Paiement PayPal ? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the PayPal payment page? We\u2019ll help you re-launch the window to complete your purchase.',
            'continue':      'Continue'
        }
    },
    'BO': {
        'zh': {
            'windowMessage': '\u6CA1\u770B\u5230PayPal\u4ED8\u6B3E\u9875\u9762\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'fr': {
            'windowMessage': 'Vous ne voyez pas la page de Paiement PayPal ? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the PayPal payment page? We\u2019ll help you re-launch the window to complete your purchase.',
            'continue':      'Continue'
        },
        'es': {
            'windowMessage': '\u00BFNo puede ver la p\u00E1gina de pago de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.',
            'continue':      'Continuar'
        }
    },
    'BN': {
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.  ',
            'continue':      'Continue'
        }
    },
    'BM': {
        'zh': {
            'windowMessage': '\u6CA1\u770B\u5230PayPal\u4ED8\u6B3E\u9875\u9762\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo puede ver la p\u00E1gina de pago de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Vous ne voyez pas la page de Paiement PayPal ? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the PayPal payment page? We\u2019ll help you re-launch the window to complete your purchase.',
            'continue':      'Continue'
        }
    },
    'BJ': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        }
    },
    'BI': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        }
    },
    'BH': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        },
        'ar': {
            'windowMessage': '\u0644\u0627 \u062A\u0631\u0649 \u0645\u062A\u0635\u0641\u062D PayPal \u0627\u0644\u0622\u0645\u0646\u061F \u0633\u0646\u0633\u0627\u0639\u062F\u0643 \u0641\u064A \u0625\u0639\u0627\u062F\u0629 \u0641\u062A\u062D \u0627\u0644\u0646\u0627\u0641\u0630\u0629 \u0644\u0627\u0633\u062A\u0643\u0645\u0627\u0644 \u0645\u0634\u062A\u0631\u064A\u0627\u062A\u0643. \u00A0 ',
            'continue':      '\u0645\u062A\u0627\u0628\u0639\u0629'
        }
    },
    'BG': {
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'BF': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        }
    },
    'BB': {
        'zh': {
            'windowMessage': '\u6CA1\u770B\u5230PayPal\u4ED8\u6B3E\u9875\u9762\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo puede ver la p\u00E1gina de pago de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Vous ne voyez pas la page de Paiement PayPal ? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the PayPal payment page? We\u2019ll help you re-launch the window to complete your purchase.',
            'continue':      'Continue'
        }
    },
    'BA': {
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'AZ': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'AW': {
        'zh': {
            'windowMessage': '\u6CA1\u770B\u5230PayPal\u4ED8\u6B3E\u9875\u9762\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo puede ver la p\u00E1gina de pago de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Vous ne voyez pas la page de Paiement PayPal ? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the PayPal payment page? We\u2019ll help you re-launch the window to complete your purchase.',
            'continue':      'Continue'
        }
    },
    'AO': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'AN': {
        'zh': {
            'windowMessage': '\u6CA1\u770B\u5230PayPal\u4ED8\u6B3E\u9875\u9762\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo puede ver la p\u00E1gina de pago de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Vous ne voyez pas la page de Paiement PayPal ? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the PayPal payment page? We\u2019ll help you re-launch the window to complete your purchase.',
            'continue':      'Continue'
        }
    },
    'AM': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'AL': {
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'AI': {
        'zh': {
            'windowMessage': '\u6CA1\u770B\u5230PayPal\u4ED8\u6B3E\u9875\u9762\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo puede ver la p\u00E1gina de pago de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Vous ne voyez pas la page de Paiement PayPal ? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the PayPal payment page? We\u2019ll help you re-launch the window to complete your purchase.',
            'continue':      'Continue'
        }
    },
    'AG': {
        'zh': {
            'windowMessage': '\u6CA1\u770B\u5230PayPal\u4ED8\u6B3E\u9875\u9762\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo puede ver la p\u00E1gina de pago de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Vous ne voyez pas la page de Paiement PayPal ? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the PayPal payment page? We\u2019ll help you re-launch the window to complete your purchase.',
            'continue':      'Continue'
        }
    },
    'AE': {
        'ar': {
            'windowMessage': '\u0644\u0627 \u062A\u0631\u0649 \u0645\u062A\u0635\u0641\u062D PayPal \u0627\u0644\u0622\u0645\u0646\u061F \u0633\u0646\u0633\u0627\u0639\u062F\u0643 \u0641\u064A \u0625\u0639\u0627\u062F\u0629 \u0641\u062A\u062D \u0627\u0644\u0646\u0627\u0641\u0630\u0629 \u0644\u0627\u0633\u062A\u0643\u0645\u0627\u0644 \u0645\u0634\u062A\u0631\u064A\u0627\u062A\u0643. \u00A0 ',
            'continue':      '\u0645\u062A\u0627\u0628\u0639\u0629'
        },
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'AD': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'CN': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u4ED8\u6B3E\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        }
    },
    'GB': {
        'fr': {
            'windowMessage': 'Vous ne voyez pas le navigateur s\u00E9curis\u00E9 PayPal\u00A0? Nous allons vous aider \u00E0 relancer la fen\u00EAtre pour effectuer votre achat.\u00A0 ',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\'ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'AR': {
        'en': {
            'windowMessage': 'Don\u2019t see the PayPal payment page? We\u2019ll help you re-launch the window to complete your purchase.',
            'continue':      'Continue'
        },
        'es': {
            'windowMessage': '\u00BFNo puede ver la p\u00E1gina de pago de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.',
            'continue':      'Continuar'
        }
    },
    'US': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat. ',
            'continue':      'Continuer'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda completar su compra. ',
            'continue':      'Continuar'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.  ',
            'continue':      'Click to Continue'
        }
    },
    'VN': {
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your payment.  ',
            'continue':      'Continue'
        }
    },
    'UA': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'ru': {
            'windowMessage': '\u041D\u0435 \u043E\u0442\u043E\u0431\u0440\u0430\u0436\u0430\u0435\u0442\u0441\u044F \u0431\u0435\u0437\u043E\u043F\u0430\u0441\u043D\u0430\u044F \u0441\u0442\u0440\u0430\u043D\u0438\u0446\u0430 PayPal \u0432 \u0431\u0440\u0430\u0443\u0437\u0435\u0440\u0435? \u041C\u044B \u043F\u043E\u043C\u043E\u0436\u0435\u043C \u0432\u0430\u043C \u043F\u043E\u0432\u0442\u043E\u0440\u043D\u043E \u0437\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044C \u043E\u043A\u043D\u043E, \u0447\u0442\u043E\u0431\u044B \u0437\u0430\u0432\u0435\u0440\u0448\u0438\u0442\u044C \u043F\u043E\u043A\u0443\u043F\u043A\u0443.\u00A0 ',
            'continue':      '\u041F\u0440\u043E\u0434\u043E\u043B\u0436\u0438\u0442\u044C'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'TW': {
        'zh': {
            'windowMessage': '\u770B\u4E0D\u5230\u5B89\u5168\u9023\u7DDA\u7684 PayPal \u700F\u89BD\u5668\uFF1F\u6211\u5011\u5C07\u6703\u91CD\u65B0\u555F\u52D5\u8996\u7A97\u4EE5\u5B8C\u6210\u4ED8\u6B3E\u3002\u00A0 ',
            'continue':      '\u7E7C\u7E8C'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your payment.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'TR': {
        'tr': {
            'windowMessage': 'G\u00FCvenli PayPal taray\u0131c\u0131s\u0131n\u0131 g\u00F6rm\u00FCyor musunuz? Al\u0131\u015Fveri\u015Finizi tamamlamak i\u00E7in pencereyi yeniden ba\u015Flatman\u0131za yard\u0131mc\u0131 olaca\u011F\u0131z.\u00A0 ',
            'continue':      'Devam'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'TH': {
        'th': {
            'windowMessage': '\u0E16\u0E49\u0E32\u0E04\u0E38\u0E13\u0E44\u0E21\u0E48\u0E40\u0E2B\u0E47\u0E19\u0E40\u0E1A\u0E23\u0E32\u0E27\u0E4C\u0E40\u0E0B\u0E2D\u0E23\u0E4C\u0E17\u0E35\u0E48\u0E21\u0E35\u0E23\u0E30\u0E1A\u0E1A\u0E04\u0E27\u0E32\u0E21\u0E1B\u0E25\u0E2D\u0E14\u0E20\u0E31\u0E22\u0E02\u0E2D\u0E07 PayPal \u0E40\u0E23\u0E32\u0E08\u0E30\u0E0A\u0E48\u0E27\u0E22\u0E04\u0E38\u0E13\u0E40\u0E1B\u0E34\u0E14\u0E2B\u0E19\u0E49\u0E32\u0E15\u0E48\u0E32\u0E07\u0E2D\u0E35\u0E01\u0E04\u0E23\u0E31\u0E49\u0E07\u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E0A\u0E33\u0E23\u0E30\u0E40\u0E07\u0E34\u0E19\u0E43\u0E2B\u0E49\u0E40\u0E23\u0E35\u0E22\u0E1A\u0E23\u0E49\u0E2D\u0E22 ',
            'continue':      '\u0E14\u0E33\u0E40\u0E19\u0E34\u0E19\u0E01\u0E32\u0E23\u0E15\u0E48\u0E2D'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your payment.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'SK': {
        'sk': {
            'windowMessage': 'Nezobrazuje sa v\u00e1m zabezpe\u010den\u00fd prehliada\u010d PayPal? Pom\u00f4\u017eeme v\u00e1m znova otvori\u0165 okno, aby ste mohli n\u00e1kup dokon\u010di\u0165.\u00A0 ',
            'continue':      'Pokra\u010dova\u0165'
        },
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'SI': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'SG': {
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your payment.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'SE': {
        'sv': {
            'windowMessage': 'Ser du inte den s\u00E4kra PayPal-webbl\u00E4saren? Vi hj\u00E4lper dig att starta om f\u00F6nstret f\u00F6r att slutf\u00F6ra ditt k\u00F6p. ',
            'continue':      'Forts\u00E4tt'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'RU': {
        'ru': {
            'windowMessage': '\u041D\u0435 \u043E\u0442\u043E\u0431\u0440\u0430\u0436\u0430\u0435\u0442\u0441\u044F \u0431\u0435\u0437\u043E\u043F\u0430\u0441\u043D\u0430\u044F \u0441\u0442\u0440\u0430\u043D\u0438\u0446\u0430 PayPal \u0432 \u0431\u0440\u0430\u0443\u0437\u0435\u0440\u0435? \u041C\u044B \u043F\u043E\u043C\u043E\u0436\u0435\u043C \u0432\u0430\u043C \u043F\u043E\u0432\u0442\u043E\u0440\u043D\u043E \u0437\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044C \u043E\u043A\u043D\u043E, \u0447\u0442\u043E\u0431\u044B \u0437\u0430\u0432\u0435\u0440\u0448\u0438\u0442\u044C \u043F\u043E\u043A\u0443\u043F\u043A\u0443.',
            'continue':      '\u041F\u0440\u043E\u0434\u043E\u043B\u0436\u0438\u0442\u044C'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.',
            'continue':      'Continue'
        }
    },
    'RO': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'PT': {
        'pt': {
            'windowMessage': 'N\u00E3o v\u00EA a indica\u00E7\u00E3o de sess\u00E3o segura PayPal no browser? Vamos ajudar a reabrir a janela para que possa concluir a sua compra.',
            'continue':      'Continuar'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'PL': {
        'pl': {
            'windowMessage': 'Nie widzisz bezpiecznej przegl\u0105darki PayPal? Pomo\u017Cemy Ci ponownie uruchomi\u0107 to okno w celu dokonania zakupu.\u00A0 ',
            'continue':      'Kontynuuj'
        },
        'en': {
            'windowMessage': 'You don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'PH': {
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your payment.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'NZ': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u4ED8\u6B3E\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 relancer la fen\u00EAtre pour effectuer votre paiement.',
            'continue':      'Continuer'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Le ayudaremos a abrir de nuevo la ventana para completar su pago.\u00A0 ',
            'continue':      'Continuar'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your payment.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'NO': {
        'no': {
            'windowMessage': 'Ser du ikke den sikre PayPal-nettleseren? Vi hjelper deg med \u00E5 starte vinduet p\u00E5 nytt s\u00E5 du kan fullf\u00F8re kj\u00F8pet.\u00A0 ',
            'continue':      'Fortsett'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'NL': {
        'nl': {
            'windowMessage': 'Ziet u geen beveiligde PayPal-browser? We helpen u het venster opnieuw te openen om uw aankoop te voltooien.\u00A0 ',
            'continue':      'Doorgaan'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'MY': {
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your payment.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'MX': {
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda completar su compra. ',
            'continue':      'Continuar'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.  ',
            'continue':      'Continue'
        }
    },
    'LV': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'ru': {
            'windowMessage': '\u041D\u0435 \u043E\u0442\u043E\u0431\u0440\u0430\u0436\u0430\u0435\u0442\u0441\u044F \u0431\u0435\u0437\u043E\u043F\u0430\u0441\u043D\u0430\u044F \u0441\u0442\u0440\u0430\u043D\u0438\u0446\u0430 PayPal \u0432 \u0431\u0440\u0430\u0443\u0437\u0435\u0440\u0435? \u041C\u044B \u043F\u043E\u043C\u043E\u0436\u0435\u043C \u0432\u0430\u043C \u043F\u043E\u0432\u0442\u043E\u0440\u043D\u043E \u0437\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044C \u043E\u043A\u043D\u043E, \u0447\u0442\u043E\u0431\u044B \u0437\u0430\u0432\u0435\u0440\u0448\u0438\u0442\u044C \u043F\u043E\u043A\u0443\u043F\u043A\u0443.\u00A0 ',
            'continue':      '\u041F\u0440\u043E\u0434\u043E\u043B\u0436\u0438\u0442\u044C'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'LU': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        },
        'de': {
            'windowMessage': 'Das PayPal-Fenster wird nicht angezeigt? Hier k\u00F6nnen Sie es wieder \u00F6ffnen und Ihren Einkauf abschlie\u00DFen.',
            'continue':      'Weiter'
        }
    },
    'LT': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'ru': {
            'windowMessage': '\u041D\u0435 \u043E\u0442\u043E\u0431\u0440\u0430\u0436\u0430\u0435\u0442\u0441\u044F \u0431\u0435\u0437\u043E\u043F\u0430\u0441\u043D\u0430\u044F \u0441\u0442\u0440\u0430\u043D\u0438\u0446\u0430 PayPal \u0432 \u0431\u0440\u0430\u0443\u0437\u0435\u0440\u0435? \u041C\u044B \u043F\u043E\u043C\u043E\u0436\u0435\u043C \u0432\u0430\u043C \u043F\u043E\u0432\u0442\u043E\u0440\u043D\u043E \u0437\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044C \u043E\u043A\u043D\u043E, \u0447\u0442\u043E\u0431\u044B \u0437\u0430\u0432\u0435\u0440\u0448\u0438\u0442\u044C \u043F\u043E\u043A\u0443\u043F\u043A\u0443.\u00A0 ',
            'continue':      '\u041F\u0440\u043E\u0434\u043E\u043B\u0436\u0438\u0442\u044C'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'KR': {
        'ko': {
            'windowMessage': '\uBCF4\uC548 PayPal \uBE0C\uB77C\uC6B0\uC800\uAC00 \uBCF4\uC774\uC9C0 \uC54A\uC73C\uC2E0\uAC00\uC694? \uCC3D\uC744 \uB2E4\uC2DC \uC2E4\uD589\uD558\uC5EC \uACB0\uC81C\uB97C \uC644\uB8CC\uD560 \uC218 \uC788\uB3C4\uB85D \uB3C4\uC640\uB4DC\uB9AC\uACA0\uC2B5\uB2C8\uB2E4.\u00A0 ',
            'continue':      '\uACC4\uC18D'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your payment.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'JP': {
        'ja': {
            'windowMessage': '\u30BB\u30AD\u30E5\u30A2\u306A\u30D6\u30E9\u30A6\u30B6\u304C\u8868\u793A\u3055\u308C\u306A\u3044\u5834\u5408\u306F\u3001\u30A6\u30A3\u30F3\u30C9\u30A6\u3092\u518D\u8D77\u52D5\u3057\u3066\u3001\u652F\u6255\u3044\u3092\u5B8C\u4E86\u3067\u304D\u308B\u3088\u3046\u304A\u624B\u4F1D\u3044\u3044\u305F\u3057\u307E\u3059\u3002',
            'continue':      '\u7D9A\u884C'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your payment.  ',
            'continue':      'Continue'
        }
    },
    'IT': {
        'it': {
            'windowMessage': 'Non vedi la pagina sicura di PayPal? Ti aiuteremo a riaprire la finestra per completare l\'acquisto.\u00A0 ',
            'continue':      'Continua'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'IN': {
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'IL': {
        'he': {
            'windowMessage': '\u05DC\u05D0 \u05E8\u05D5\u05D0\u05D4 \u05D0\u05EA \u05D3\u05E4\u05D3\u05E4\u05DF PayPal \u05D4\u05DE\u05D0\u05D5\u05D1\u05D8\u05D7? \u05E0\u05E2\u05D6\u05D5\u05E8 \u05DC\u05DA \u05DC\u05E4\u05EA\u05D5\u05D7 \u05DE\u05D7\u05D3\u05E9 \u05D0\u05EA \u05D4\u05D7\u05DC\u05D5\u05DF \u05DB\u05D3\u05D9 \u05DC\u05D4\u05E9\u05DC\u05D9\u05DD \u05D0\u05EA \u05D4\u05E7\u05E0\u05D9\u05D9\u05D4 \u05E9\u05DC\u05DA.\u00A0 ',
            'continue':      '\u05D4\u05DE\u05E9\u05DA'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'IE': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'HU': {
        'hu': {
            'windowMessage': 'Nem l\u00e1tja a biztons\u00e1gos PayPal-b\u00f6ng\u00e9sz\u0151t? Seg\u00edt\u00fcnk \u00fajra bet\u00f6lteni az ablakot, hogy befejezhesse a v\u00e1s\u00e1rl\u00e1st.\u00A0 ',
            'continue':      'Folytat\u00e1s'
        },
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'ID': {
        'id': {
            'windowMessage': 'Browser PayPal yang aman tidak terlihat? Kami akan membantu menampilkan ulang jendela untuk menyelesaikan pembayaran Anda.\u00A0 ',
            'continue':      'Lanjutkan'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your payment.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'HK': {
        'zh': {
            'windowMessage': '\u770B\u4E0D\u5230\u5B89\u5168\u7684 PayPal \u700F\u89BD\u5668\u8996\u7A97\uFF1F\u6211\u5011\u6703\u52A9\u4F60\u91CD\u65B0\u958B\u555F\u8996\u7A97\uFF0C\u4EE5\u5B8C\u6210\u4ED8\u6B3E\u3002',
            'continue':      '\u7E7C\u7E8C'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your payment.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'GROUP-LATAM': {
        'zh': {
            'windowMessage': '\u6CA1\u770B\u5230PayPal\u4ED8\u6B3E\u9875\u9762\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'fr': {
            'windowMessage': 'Vous ne voyez pas la page de Paiement PayPal ? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'es': {
            'windowMessage': '\u00BFNo puede ver la p\u00E1gina de pago de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.',
            'continue':      'Continuar'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the PayPal payment page? We\u2019ll help you re-launch the window to complete your purchase.',
            'continue':      'Continue'
        }
    },
    'GROUP-EMEA': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'ru': {
            'windowMessage': '\u041D\u0435 \u043E\u0442\u043E\u0431\u0440\u0430\u0436\u0430\u0435\u0442\u0441\u044F \u0431\u0435\u0437\u043E\u043F\u0430\u0441\u043D\u0430\u044F \u0441\u0442\u0440\u0430\u043D\u0438\u0446\u0430 PayPal \u0432 \u0431\u0440\u0430\u0443\u0437\u0435\u0440\u0435? \u041C\u044B \u043F\u043E\u043C\u043E\u0436\u0435\u043C \u0432\u0430\u043C \u043F\u043E\u0432\u0442\u043E\u0440\u043D\u043E \u0437\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044C \u043E\u043A\u043D\u043E, \u0447\u0442\u043E\u0431\u044B \u0437\u0430\u0432\u0435\u0440\u0448\u0438\u0442\u044C \u043F\u043E\u043A\u0443\u043F\u043A\u0443.\u00A0 ',
            'continue':      '\u041F\u0440\u043E\u0434\u043E\u043B\u0436\u0438\u0442\u044C'
        },
        'pt': {
            'windowMessage': 'N\u00E3o v\u00EA a indica\u00E7\u00E3o de sess\u00E3o segura PayPal no browser? Vamos ajudar a reabrir a janela para que possa concluir a sua compra.',
            'continue':      'Continuar'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        },
        'de': {
            'windowMessage': 'Sie sehen das sichere Browserfenster von PayPal nicht? Hier k\u00F6nnen Sie es wieder \u00F6ffnen und Ihren Einkauf abschlie\u00DFen.',
            'continue':      'Weiter'
        },
        'da': {
            'windowMessage': 'Kan du ikke se PayPals sikre browser? Vi hj\u00E6lper dig med at genstarte vinduet, s\u00E5 du kan betale.',
            'continue':      'Forts\u00E6t'
        },
        'ar': {
            'windowMessage': '\u0644\u0627 \u062A\u0631\u0649 \u0645\u062A\u0635\u0641\u062D PayPal \u0627\u0644\u0622\u0645\u0646\u061F \u0633\u0646\u0633\u0627\u0639\u062F\u0643 \u0641\u064A \u0625\u0639\u0627\u062F\u0629 \u0641\u062A\u062D \u0627\u0644\u0646\u0627\u0641\u0630\u0629 \u0644\u0627\u0633\u062A\u0643\u0645\u0627\u0644 \u0645\u0634\u062A\u0631\u064A\u0627\u062A\u0643. \u00A0 ',
            'continue':      '\u0645\u062A\u0627\u0628\u0639\u0629'
        }
    },
    'GROUP-APAC': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002',
            'continue':      '\u7EE7\u7EED'
        },
        'ko': {
            'windowMessage': '\uBCF4\uC548 PayPal \uBE0C\uB77C\uC6B0\uC800\uAC00 \uBCF4\uC774\uC9C0 \uC54A\uC73C\uC2E0\uAC00\uC694? \uCC3D\uC744 \uB2E4\uC2DC \uC2E4\uD589\uD558\uC5EC \uAD6C\uB9E4\uB97C \uC644\uB8CC\uD560 \uC218 \uC788\uB3C4\uB85D \uB3C4\uC640\uB4DC\uB9AC\uACA0\uC2B5\uB2C8\uB2E4. ',
            'continue':      '\uACC4\uC18D'
        },
        'id': {
            'windowMessage': 'Browser PayPal yang aman tidak terlihat? Kami akan membantu menampilkan ulang jendela untuk menyelesaikan pembelian Anda. ',
            'continue':      'Lanjutkan'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat. ',
            'continue':      'Continuer'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda completar su compra. ',
            'continue':      'Continuar'
        },
        'en': {
            'windowMessage': 'Don\u2019t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.  ',
            'continue':      'Continue'
        }
    },
    'GR': {
        'el': {
            'windowMessage': '\u0394\u03b5\u03bd \u03b2\u03bb\u03ad\u03c0\u03b5\u03c4\u03b5 \u03c4\u03bf \u03b1\u03c3\u03c6\u03b1\u03bb\u03ad\u03c2 \u03c0\u03c1\u03cc\u03b3\u03c1\u03b1\u03bc\u03bc\u03b1 \u03c0\u03b5\u03c1\u03b9\u03ae\u03b3\u03b7\u03c3\u03b7\u03c2 PayPal; \u0398\u03b1 \u03c3\u03b1\u03c2 \u03b2\u03bf\u03b7\u03b8\u03ae\u03c3\u03bf\u03c5\u03bc\u03b5 \u03bd\u03b1 \u03b5\u03c0\u03b1\u03bd\u03b5\u03ba\u03ba\u03b9\u03bd\u03ae\u03c3\u03b5\u03c4\u03b5 \u03c4\u03bf \u03c0\u03b1\u03c1\u03ac\u03b8\u03c5\u03c1\u03bf \u03b3\u03b9\u03b1 \u03bd\u03b1 \u03bf\u03bb\u03bf\u03ba\u03bb\u03b7\u03c1\u03ce\u03c3\u03b5\u03c4\u03b5 \u03c4\u03b7\u03bd \u03b1\u03b3\u03bf\u03c1\u03ac \u03c3\u03b1\u03c2.\u00A0 ',
            'continue':      '\u03a3\u03c5\u03bd\u03ad\u03c7\u03b5\u03b9\u03b1'
        },
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'FR': {
        'fr': {
            'windowMessage': 'Vous ne voyez pas le navigateur s\u00E9curis\u00E9 PayPal\u00A0? Nous allons vous aider \u00E0 relancer la fen\u00EAtre pour effectuer votre achat.\u00A0 ',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'ES': {
        'es': {
            'windowMessage': '\u00BFNo ve el s\u00EDmbolo de navegaci\u00F3n segura de PayPal? Le ayudaremos a abrir de nuevo la ventana para completar la compra. ',
            'continue':      'Continuar'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'FI': {
        'fi': {
            'windowMessage': 'Eik\u00f6 suojattua PayPal-selainta n\u00e4y? Autamme avaamaan ikkunan uudelleen oston viimeistely\u00e4 varten.\u00A0 ',
            'continue':      'Jatka'
        },
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'EE': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'ru': {
            'windowMessage': '\u041D\u0435 \u043E\u0442\u043E\u0431\u0440\u0430\u0436\u0430\u0435\u0442\u0441\u044F \u0431\u0435\u0437\u043E\u043F\u0430\u0441\u043D\u0430\u044F \u0441\u0442\u0440\u0430\u043D\u0438\u0446\u0430 PayPal \u0432 \u0431\u0440\u0430\u0443\u0437\u0435\u0440\u0435? \u041C\u044B \u043F\u043E\u043C\u043E\u0436\u0435\u043C \u0432\u0430\u043C \u043F\u043E\u0432\u0442\u043E\u0440\u043D\u043E \u0437\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044C \u043E\u043A\u043D\u043E, \u0447\u0442\u043E\u0431\u044B \u0437\u0430\u0432\u0435\u0440\u0448\u0438\u0442\u044C \u043F\u043E\u043A\u0443\u043F\u043A\u0443.\u00A0 ',
            'continue':      '\u041F\u0440\u043E\u0434\u043E\u043B\u0436\u0438\u0442\u044C'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'DK': {
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        },
        'da': {
            'windowMessage': 'Kan du ikke se PayPals sikre browser? Vi hj\u00E6lper dig med at genstarte vinduet, s\u00E5 du kan betale.',
            'continue':      'Forts\u00E6t'
        }
    },
    'CZ': {
        'cs': {
            'windowMessage': 'Nezobrazuje se v\u00e1m bezpe\u010dn\u00fd prohl\u00ed\u017ee\u010d PayPal? Pom\u016f\u017eeme v\u00e1m okno znovu otev\u0159\u00edt, abyste mohli n\u00e1kup dokon\u010dit.',
            'continue':      'Pokra\u010dovat'
        },
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u8D2D\u7269\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'fr': {
            'windowMessage': 'Le navigateur s\u00E9curis\u00E9 de PayPal n\'appara\u00EEt pas\u00A0? Nous allons vous aider \u00E0 rouvrir la fen\u00EAtre pour finaliser votre achat.',
            'continue':      'Continuer'
        },
        'es': {
            'windowMessage': '\u00BFNo ve el navegador seguro de PayPal? Abriremos la ventana nuevamente para que pueda concluir su compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'DE': {
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        },
        'de': {
            'windowMessage': 'Sie sehen das sichere Browserfenster von PayPal nicht? Hier k\u00F6nnen Sie es wieder \u00F6ffnen und Ihren Einkauf abschlie\u00DFen.',
            'continue':      'Weiter'
        }
    },
    'CH': {
        'fr': {
            'windowMessage': 'Vous ne voyez pas le navigateur s\u00E9curis\u00E9 PayPal\u00A0? Nous allons vous aider \u00E0 relancer la fen\u00EAtre pour effectuer votre achat.\u00A0 ',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        },
        'de': {
            'windowMessage': 'Sie sehen das sichere Browserfenster von PayPal nicht? Hier k\u00F6nnen Sie es wieder \u00F6ffnen und Ihren Einkauf abschlie\u00DFen.',
            'continue':      'Weiter'
        }
    },
    'CA': {
        'fr': {
            'windowMessage': 'Vous ne voyez pas le navigateur s\u00E9curis\u00E9 de PayPal\u00A0? Nous vous aiderons \u00E0 relancer la fen\u00EAtre afin d\'effectuer votre achat.\u00A0 ',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you relaunch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'C2': {
        'zh': {
            'windowMessage': '\u6CA1\u6709\u627E\u5230\u5B89\u5168\u7684PayPal\u6D4F\u89C8\u5668\uFF1F\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u91CD\u542F\u7A97\u53E3\u4EE5\u5B8C\u6210\u4ED8\u6B3E\u3002\u00A0 ',
            'continue':      '\u7EE7\u7EED'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your payment.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'BE': {
        'nl': {
            'windowMessage': 'Ziet u de beveiligde PayPal-browser niet? We helpen u het venster opnieuw te openen om uw aankoop te voltooien.\u00A0 ',
            'continue':      'Doorgaan'
        },
        'fr': {
            'windowMessage': 'Vous ne voyez pas le navigateur s\u00E9curis\u00E9 PayPal\u00A0? Nous allons vous aider \u00E0 relancer la fen\u00EAtre pour effectuer votre achat.\u00A0 ',
            'continue':      'Continuer'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'BR': {
        'pt': {
            'windowMessage': 'N\u00E3o est\u00E1 vendo o navegador seguro do PayPal? Ajudaremos voc\u00EA a reabrir a janela para concluir a compra.\u00A0 ',
            'continue':      'Continuar'
        },
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\u2019ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    },
    'AU': {
        'en': {
            'windowMessage': 'Don\'t see the secure PayPal browser? We\'ll help you re-launch the window to complete your purchase.\u00A0 ',
            'continue':      'Continue'
        }
    }
};
